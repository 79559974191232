@keyframes slideInDown {
    from {
      transform: translate3d(0, -100%, 0);
      visibility: visible;
    }
  
    to {
      transform: translate3d(0, 0, 0);
    }
  }
  
  .slideInDown {
    animation-name: slideInDown;
  }
  
  
  .animated {
    animation-duration: 1s;
    animation-fill-mode: both;
  }
  
  .animated.infinite {
    animation-iteration-count: infinite;
  }
  
  .animated.hinge {
    animation-duration: 2s;
  }
  
  .animated.flipOutX,
  .animated.flipOutY,
  .animated.bounceIn,
  .animated.bounceOut {
    animation-duration: .75s;
  }
  
  
  
  @keyframes slideInUp {
    from {
      transform: translate3d(0, 100%, 0);
      visibility: visible;
    }
  
    to {
      transform: translate3d(0, 0, 0);
    }
  }
  
  .slideInUp {
    animation-name: slideInUp;
  }
  
  .animated1 {
    animation-duration: 1s;
    animation-fill-mode: both;
  }
  
  
  
  @keyframes zoomIn {
    from {
      opacity: 0;
      transform: scale3d(.3, .3, .3);
    }
  
    50% {
      opacity: 1;
    }
  }
  
  .zoomIn {
    animation-name: zoomIn;
    animation-duration: 2s; /* Animation duration of 1 second */
    animation-timing-function: ease-in-out; /* Slow animation effect */
  }
  
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
  }
  
  .fadeIn {
    animation-name: fadeIn;
  }
  
  
  .slide-up-animation {
    opacity: 0;
    transform: translateY(200%);
    animation: slideUp 1.6s ease-in-out forwards;
    animation-delay: 0.2s; /* Add a slight delay before the animation starts */
  }
  
  @keyframes slideUp {
    from {
      opacity: 0;
      transform: translateY(200%);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .slide-up-animation.delay-1 {
    animation-delay: 0s;
  }
  
  .slide-up-animation.delay-2 {
    animation-delay: 0.5s;
  }
  
  .slide-up-animation.delay-3 {
    animation-delay: 1s;
  }
  
  
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translate3d(0, 100%, 0);
    }
  
    to {
      opacity: 1;
      transform: none;
    }
  }
  
  .fadeInUp {
    animation-name: fadeInUp;
  }
  
  @import url('https://fonts.googleapis.com/css2?family=Jost:wght@100;200;300;400&display=swap');
  
  .container-xl {
    width: 100%;
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
    margin-right: auto;
    margin-left: auto;
    max-width: 86%;
    height: 80px; /* Adjust the height as needed */
    display: flex;
    align-items: center;
    justify-content: center; 
  }
  
  .hero-header {
    margin-bottom: 1rem;
    padding: 1rem 0;
    border-radius: 10px;
    background:
        url(../assets/blob-top-left.png),
        url(../assets/blob-top-right.png),
        url(../assets/blob-bottom-left.png),
        url(../assets/blob-bottom-right.png),
        url(../assets/blob-center.png);
  
     background-position:
        left 0px top 0px,
        right 0px top 0px,
        left 0px bottom 0px,
        right 0px bottom 0px,
        center center,
        center bottom;
    background-repeat: no-repeat;
  }
  
  @media (max-width: 991.98px) {
    .hero-header {
        padding: 6rem 0 9rem 0;
    }
  }
  
  .text-white {
    color: #fff !important;
  }
  
  .thin-line {
    background-color: transparent;
    border: none;
    height: 1px;
    color: #808080;
  }
  
  .fontclass{
    font-family: 'Jost', sans-serif;
    font-weight: 570;
    color: #13226c;
  }
  
  .title{
    font-family: 'Jost', sans-serif;
    font-weight: 600;
    font-size: 1.3em;
  }
  
  .container-xxl {
    width: 100%;
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
    margin-right: auto;
    margin-left: auto;
    max-width: 86%; /* Adjust the value as needed */
  }
  
  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: 30px;
    margin-left: 30px;
    margin-bottom: 4em;
  }
  
  .align-items-center {
    align-items: center !important;
  }
  
  .col-lg-12 {
     max-width: 100%;
   }
  
  
   .img-fluid.zoomIn {
    width: 200px; 
    height: auto;
  }
  
  
  .text-center {
    text-align: center !important;
  }
  
  
  .header{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2em;
  }
  
  .border-bottom {
    border-bottom: 1px solid white;
  }
  
  
  .text-light {
    color: #f8f9fa !important;
  }
  
  
  .text-light a {
    color: white; /* Set the default link color to white */
    text-decoration: none; /* Remove underline */
    font-weight: 600;
  }
  
  .text-light a:hover,
  .text-light a:focus {
    color: #64be28 !important; /* Set the link color to green on hover and focus */
  }
  
  
  .footer {
    margin-top: 3rem;
    padding-top: 3.5rem;
    border-radius: 10px;
    background:
        url(../assets/map.png);
    background-position:
        center top,
        center center;
    background-repeat: no-repeat;
  }
  
  

  .footer .copyright {
  padding: 25px 0;
  font-size: 14px;
  border-top: 1px solid rgba(256, 256, 256, .1);
  }
  
  .footer .copyright a {
  color: var(--light);
  }
  
  
  .footer .footer-menu a {
  margin-left: 30px;
  padding-right: 15px;
  }
  
  
  .image-box {
    flex: 1;
    width: 100%;
    height: 200px;
    position: relative;
    overflow: hidden;
  }
  
  .image-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  .image-box:hover img {
    transform: scale(1.1);
  }
  
  .image-box::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #13226c;
    opacity: 0;
    transition: opacity 0.5s;
  }
  
  .image-box:hover::after {
    opacity: 1;
  }
  
  
  .container {
    width: 100%;
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
    margin-right: auto;
    margin-left: auto;
    max-width: 85%; /* Adjust the value as needed */
  }
  
  
  
  .text-color {
    color: #13226c !important;
  }
  
  
  .text-color a {
    color: 13226c; /* Set the default link color to white */
    text-decoration: none; /* Remove underline */
    font-weight: 500;
  }
  
  .text-color a:hover,
  .text-color a:focus {
    color: #64be28 !important; /* Set the link color to green on hover and focus */
  }
  
  
  /* Home Page */
  
  .card-row {
    flex-wrap: wrap;
    margin: 3em;
  }
  
  .card {
    flex: 1 0 20%; /* Adjust the width as desired */
    width: 100%;
    max-width: 400px;
    height: 100%;
    padding: 1em;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
  }
  
  .image {
    width: 100%; /* Set the image width to 100% of its container */
    height: 100%; /* Let the height adjust proportionally */
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  .image-container {
    position: relative;
    width: 100%; /* Set the desired width */
    height: 200px; /* Set the desired height */
    overflow: hidden;
  }
  
  .image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  .image-container:hover img {
    transform: scale(1.2);
  }
  
  
  /* Footer */
  

  .col-md-6 {
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%;
  }
  
  .mb-3 {
    margin-bottom: 1rem !important;
    display: inline-block;
    white-space: nowrap;
  }
  
  .mb-md-0 {
    margin-bottom: 0 !important;
   }
  
  
   /* .btn-lg{padding:10px 16px;font-size:18px;line-height:1.3333333;border-radius:6px}
  
   .btn{display:inline-block;margin-bottom:0;font-weight:400;text-align:center;vertical-align:middle;-ms-touch-action:manipulation;touch-action:manipulation;cursor:pointer;border:1px solid transparent;white-space:nowrap;padding:6px 12px;font-size:14px;line-height:1.6;border-radius:4px;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}
  
   .btn-secondary {
    color: #fff;
    background-color: #868e96;
    border-color: #868e96;
  }
  
  .btn-lg-square {
    width: 48px;
    height: 48px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
    border-radius: 50px;
  } */
  
  
  /* ERP Implementation */
  
  
  .col-12 {
        flex: 0 0 100%;
    max-width: 100%;
  }
  


  /* login page css */
  
  /* login background */

   .login_background {
    width: 100%;
    top: 0;
    height: 100vh;
    background-image: url("https://plus.unsplash.com/premium_photo-1661881251976-9fc9bbb90c4e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80");
    background-attachment: fixed;
  }
  
   
  


.image-disable {
  position: relative;
  width: 100%; /* Set the desired width */
  height: 200px; /* Set the desired height */
  overflow: hidden;
}

.image-disable  img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}


